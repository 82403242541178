.about-us-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  padding-left: 100px;
  padding-right: 50px;
  padding-top: 70px;
  padding-bottom: 70px;
}
.about-us-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 30px;
}
.about-us-text {
  font-weight: 700;
  color: #091a24;
  font-size: 48px;
  margin: 20px;
}
.about-us-cards-wrapper {
  display: flex;
  column-gap: 24px;
}
.about-us-card-one {
  flex: 1 0 auto;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
 
  
}
.about-us-card-two {
  flex: 1 0 auto;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
 
}
.about-us-card-three {
  flex: 1 0 auto;
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  
}
.about-us-card-four {
  flex: 1 0 auto;
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  
}

@media (max-width: 900px) {
  .about-us-wrapper {
    padding-left: 30px;
    padding-right: 30px;
    
  }
  .about-us-cards-wrapper {
    flex-direction: column;
    

  }
  .about-us-card-one {
    width: 100%;
    

  }
  .about-us-card-two {
    width: 100%;

  }
  .about-us-card-three {
    width: 100%;
  }
  .about-us-card-four {
    width: 100%;
  }
}
