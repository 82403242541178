.navbar-cover{
    display: flex;
    // background-color: silver;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 38px;
    padding-right: 15px;
    align-items: center;

}

.nav-logo-img{
    width: 59px;
    height: 55px;
    cursor: pointer;
}
.logo-name{
    font-size: 70px;
    color: #033C59;
    font-family: sen-bold;
    line-height: 84.22px;
    letter-spacing: -0.28px;
    margin-left: 31px;
    cursor: pointer;

}
.nav-list{
    flex-grow: 1;


}
.nav-list ul{

    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // background-color: green;


}
.nav-list ul li{

margin-left: 38px;
color: #1D293F;
line-height: 19.25px;
letter-spacing: -0.28px;
font-size: 16px;
font-family: sen-bold;
cursor: pointer;

}
@media(max-width:1123px){
    .nav-logo-img{
        width: 40px;
        height: 55px;
        cursor: pointer;
    }
    .logo-name{
        font-size: 40px;
        color: #033C59;
        font-family: sen-bold;
        line-height: 84.22px;
        letter-spacing: -0.28px;
        margin-left: 20px;
        cursor: pointer;
    }
    .nav-list ul li{

        margin-left: 20px;
        color: #1D293F;
        line-height: 19.25px;
        letter-spacing: -0.28px;
        font-size: 16px;
        font-family: sen-bold;

        }
}
.menu-icon-div{
    display: none;
}
@media(max-width:869px){
    .nav-list{
        position: relative;
    }
    .nav-list ul li{
        display: none;
    }
    .menu-icon-div{
        display: block;
        position: absolute;
        right: 23px;
    }
}