.contact-us-comp-cover {
  background-image: url("../../images/contact-us-banner.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 70px;
  padding-left: 131px;
  font-size: 70px;
  font-family: sen-bold;
  color: white;
  line-height: 70px;
  letter-spacing: -1px;
  width: 100%;
}
@media (max-width: 1355px) {
  .contact-us-comp-cover {
    background-image: url("../../images/contact-us-banner.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 80px;
    padding-bottom: 70px;
    padding-left: 40px;
    font-size: 70px;
    font-family: sen-bold;
    color: white;
    line-height: 70px;
    letter-spacing: -1px;
  }
}
@media (max-width: 649px) {
  .contact-us-comp-cover {
    background-image: url("../../images/contact-us-banner.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 80px;
    padding-bottom: 70px;
    padding-left: 24px;
    font-size: 30px;
    font-family: sen-bold;
    color: white;
    line-height: 40px;
    letter-spacing: -1px;
  }
}
