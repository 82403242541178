.selling-with-odiopay-cover {
  // margin-top: 103px;
  background: white;
  padding-top: 163px;
  padding-left: 240px;
  padding-right: 240px;
  padding-bottom: 159px;
}
.selling-with-odiopay-title {
  text-align: center;
  font-size: 58px;
  font-family: sen-medium;
  color: #1d293f;
  line-height: 64px;
  letter-spacing: -1px;
  margin-bottom: 52px;
}
.selling-card-cover {
  position: relative;
  // background: white;
  padding-left: 29px;
}
.selling-logo-cover {
  background: #47b638;
  width: 97px;
  height: 97px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 32px 54px rgba(37, 138, 255, 0.224553);
  z-index: 2;
}
.selling-card-title {
  font-size: 24px;
  line-height: 34px;
  color: #1d293f;
  font-family: sen-bold;
  margin-top: 37px;
  margin-bottom: 20px;
}
.selling-card-sub-title {
  color: #7c8087;
  font-size: 16px;
  line-height: 28px;
  font-family: sen-bold;
}
.sell-arrow {
  display: block !important;
}
@media (max-width: 1111px) {
  .selling-with-odiopay-cover {
    // margin-top: 103px;
    background: white;
    padding-top: 163px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 159px;
  }
}
@media (max-width: 767px) {
  .sell-arrow {
    display: none !important;
  }
}
@media (max-width: 500px) {
  .selling-with-odiopay-cover {
    // margin-top: 103px;
    background: white;
    padding-top: 63px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 96px;
  }
  .selling-with-odiopay-title {
    text-align: center;
    font-size: 30px;
    font-family: sen-medium;
    color: #1d293f;
    line-height: 36px;
    letter-spacing: -1px;
    margin-bottom: 24px;
  }
  .selling-card-cover {
    position: relative;
    // background: white;
    padding-left: 0px;
  }
  .selling-logo-cover {
    background: #47b638;
    width: 97px;
    height: 97px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 32px 54px rgba(37, 138, 255, 0.224553);
    z-index: 1000;
  }
  .selling-card-title {
    font-size: 24px;
    line-height: 34px;
    color: #1d293f;
    font-family: sen-bold;
    margin-top: 37px;
    margin-bottom: 20px;
  }
  .selling-card-sub-title {
    color: #7c8087;
    font-size: 16px;
    line-height: 28px;
    font-family: sen-bold;
  }
  .overall-card-cover {
    padding-top: 0px !important;
    margin-bottom: 32px;
  }
  .sell-arrow {
    display: none !important;
  }
}
