.slider-actual-image {
  width: 100%;
  height: 100%;
}
.dasktop-carosel {
  position: relative;
  display: block;
}
// .carousel-root{
//     height: calc(100vh - 104px) !important;
// }
// .carouseal-cover{

//     // background:#E9F7FE !important;
//     height: calc(100vh - 100px) !important;
// }
.carousel-1 {
  height: calc(100vh - 104px);
  width: 100%;
  background: linear-gradient(
      to bottom,
      rgb(0, 0, 0, 0.12),
      rgb(67, 67, 67, 0.32)
    ),
    url("../../images/slider1.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-2 {
  height: calc(100vh - 104px);
  width: 100%;
  background: linear-gradient(
      to bottom,
      rgb(0, 0, 0, 0.12),
      rgb(67, 67, 67, 0.32)
    ),
    url("../../images/slider2.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.carousel.carousel-slider {
  // height: calc(100vh - 104px) !important;
  // background:#E9F7FE !important;
}

.carousel-status {
  display: none;
}

.slider-caption-mobile-and-tab {
  display: none;
}

.carousel .thumbs {
  display: none;
}
.slider-caption {
  z-index: 1000;
  position: absolute;
  top: 20%;
  color: black;
  padding-left: 150px;
}
.slider-caption-title {
  line-height: 60px;
  font-size: 50px;
  letter-spacing: -1px;
  font-family: sen-bold;
  width: 100%;
  text-align: left;
  margin-bottom: 37px;
}
.slider-caption-sub-ttitle {
  line-height: 28px;
  font-size: 18px;
  font-family: sen-bold;
  text-align: left;
  width: 100%;
}

.desktop-carousel-field-cover {
  display: block;
  position: absolute;
  height: 120px;
  width: 100%;
  z-index: 1;
  bottom: 15%;
  padding-left: 150px;
  padding-right: 87px;
}
@media (max-width: 1355px) {
  .slider-actual-image {
    width: 100%;
  }

  .slider-caption {
    z-index: 1000;
    position: absolute;
    top: 25%;
    color: black;
    padding-left: 40px;
  }
  .slider-caption-title {
    line-height: 77px;
    font-size: 60px;
    letter-spacing: -1px;
    font-family: sen-bold;
    width: 100%;
    text-align: left;
    margin-bottom: 37px;
  }
  .slider-caption-sub-ttitle {
    line-height: 34px;
    font-size: 21px;
    font-family: sen-medium;
    text-align: left;
    width: 100%;
  }
  .carouseal-cover {
    height: auto !important;
  }

  .carousel.carousel-slider {
    height: auto !important;
  }
  .desktop-carousel-field-cover {
    display: block;
    position: absolute;
    height: 70px;
    z-index: 1;
    bottom: 60px;
    width: 100%;
    z-index: 1;
    padding-left: 40px;
    padding-right: 87px;
  }
}
@media (max-width: 1220px) {
  .desktop-carousel-field-cover {
    height: fit-content;
  }
}

@media (max-width: 1111px) {
  .slider-actual-image {
    width: 100%;
  }

  .slider-caption {
    z-index: 1000;
    position: absolute;
    top: 40% !important;
    max-height: 100px;
    padding-left: 40px;
  }
  .slider-caption-title {
    line-height: 45px;
    font-size: 30px;
    letter-spacing: -1px;
    font-family: sen-bold;
    width: 75%;
    text-align: left;
    margin-bottom: 10px;
    margin-top: -20vh;
  }
  .slider-caption-sub-ttitle {
    line-height: 34px;
    font-size: 21px;
    font-family: sen-bold;
    text-align: left;
    width: 60%;
  }
  .carouseal-cover {
    height: auto !important;
  }

  // .carousel.carousel-slider {
  //   height: auto !important;
  // }
}
@media (max-width: 797px) {
  .slider-caption {
    z-index: 1000;
    position: absolute;
    top: 5%;
    color: white;
    padding-left: 40px;
  }
}
@media (max-width: 649px) {
  .control-dots {
    display: none;
  }
  .carousel-1 {
    height: calc(100vh - 104px);
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgb(0, 0, 0, 0.12),
        rgb(67, 67, 67, 0.32)
      ),
      url("../../images/slider1-mobile.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
  }

  .carousel-2 {
    height: calc(100vh - 104px);
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgb(0, 0, 0, 0.12),
        rgb(67, 67, 67, 0.32)
      ),
      url("../../images/slider2-mobile.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-caption {
    display: none;
  }
  .slider-caption-mobile-and-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
    position: absolute;
    bottom: 0;
    color: white;
    padding-left: 24px;
    min-height: 200px;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .slider-caption-title {
    line-height: 40px;
    font-size: 30px;
    letter-spacing: -1px;
    font-family: sen-bold;
    width: 100%;
    text-align: left;
  }
  .slider-caption-sub-ttitle {
    line-height: 30px;
    font-size: 18px;
    font-family: sen-medium;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
  }
  .desktop-carousel-field-cover {
    display: none;
  }

  .bg-screen-break {
    display: none;
  }
}
@media (min-width: 1200px) {
  .bg-screen-break {
    display: block;
  }
}
