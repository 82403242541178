@font-face {
  font-family: sen-medium;
  src: url("./fonts/Sen/Sen-Regular.ttf");
}
@font-face {
  font-family: sen-bold;
  src: url("./fonts/Sen/Sen-Bold.ttf");
}

@font-face {
  font-family: sen-extra-bold;
  src: url("./fonts/Sen/Sen-ExtraBold.ttf");
}
// *{
//   overflow-x: hidden !important;
// }
body {
  overflow-x: hidden !important;
}

.app-max-width {
  max-width: 2000px;
  margin: 0 auto !important;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  // box-shadow: inset 0 0 5px grey;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #47b638;
  border-radius: 10px;
}
