.app-form-footer-div {
  .question-and-button-div {
    padding: 39px 150px 13px 150px !important;
    background-color: #f8f8f8;
    .app-footer-question-content {
      .app-footer-title-content {
        font-size: 40px;
        letter-spacing: -0.5px;
        line-height: 60px;
        color: #323232;
        font-family: sen-bold;
        margin-bottom: 12px;
      }
      .app-footer-subtitle-content {
        font-size: 16px;
        color: #969696;
        line-height: 28.8px;
        font-size: Open_Sans_Light;
        margin-bottom: 20px;
      }
    }
    .app-footer-button {
      display: flex;
      justify-content: flex-end;
    }
  }
  .app-footer-form-logo {
    padding: 50px 150px 127px 150px;
    background-color: #091a24;
  }
}
.about-us-contact-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 39px 150px 13px 150px !important;
  background-color: #f8f8f8;
  .app-footer-question-content {
    .app-footer-title-content {
      font-size: 40px;
      letter-spacing: -0.5px;
      line-height: 60px;
      color: #323232;
      font-family: sen-bold;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
    }
    .app-footer-subtitle-content {
      font-size: 16px;
      color: #969696;
      line-height: 28.8px;
      font-size: Open_Sans_Light;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
  }
  .about-us-contact-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
.app-footer-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.footer-content-container {
  display: flex;
  width: 100%;
}
.social-media-icon-container {
  display: flex;
  margin-top: 12px;
}
.app-form-footer-br {
  display: block;
}
.footer-mobile-link-div {
  margin-top: 0px !important;
}
@media (max-width: 1355px) {
  .about-us-contact-wrapper {
    padding: 39px 40px 13px 40px !important;
    .app-footer-question-content {
      .app-footer-title-content {
        font-size: 40px;
        letter-spacing: -0.5px;
        line-height: 60px;
        margin-bottom: 12px;
      }
      .app-footer-subtitle-content {
        font-size: 16px;
        color: #969696;
        line-height: 28.8px;
      }
    }
  }
  .app-form-footer-div {
    .question-and-button-div {
      padding: 39px 40px 13px 40px !important;
      background-color: #f8f8f8;
      .app-footer-question-content {
        .app-footer-title-content {
          font-size: 40px;
          letter-spacing: -0.5px;
          line-height: 60px;
          color: #323232;
          font-family: sen-bold;
          margin-bottom: 12px;
        }
        .app-footer-subtitle-content {
          font-size: 16px;
          color: #969696;
          line-height: 28.8px;
          font-size: Open_Sans_Light;
        }
      }
      .app-footer-button {
        display: flex;
        justify-content: flex-end;
      }
    }
    .app-footer-form-logo {
      padding: 50px 40px 127px 40px;
    }
  }
  .app-form-footer-br {
    display: block;
  }
  .footer-mobile-link-div {
    margin-top: 0px !important;
  }
}

@media (max-width: 767px) {
  .about-us-contact-wrapper {
    padding: 39px 24px 13px 24px !important;

    .app-footer-question-content {
      .app-footer-title-content {
        font-size: 30px;
        letter-spacing: -0.5px;
        line-height: 40px;
        margin-bottom: 12px;
      }
      .app-footer-subtitle-content {
        font-size: 16px;
        line-height: 28.8px;
      }
    }
  }
  .app-form-footer-div {
    .question-and-button-div {
      padding: 39px 24px 13px 24px !important;
      background-color: #f8f8f8;
      .app-footer-question-content {
        .app-footer-title-content {
          font-size: 30px;
          letter-spacing: -0.5px;
          line-height: 40px;
          color: #323232;
          font-family: sen-bold;
          margin-bottom: 12px;
        }
        .app-footer-subtitle-content {
          font-size: 16px;
          color: #969696;
          line-height: 28.8px;
          font-size: Open_Sans_Light;
        }
      }
      .app-footer-button {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
    .app-footer-form-logo {
      padding: 50px 24px 127px 24px;
    }
  }
  .footer-content-container {
    flex-direction: column;
  }

  .app-form-footer-br {
    display: none;
  }
  .footer-button-contact-us {
    width: 100% !important;
  }
  .footer-mobile-link-div {
    margin-top: 40px !important;
  }
}
