.services-cover-div {
  // padding-top: 89px;
  padding-bottom: 84px;
  background-color: #f9f9fb;
  padding-left: 187px;
  padding-right: 118px;
}
.white-services-cover-div {
  // padding-top: 89px;
  padding-bottom: 84px;
  background-color: white;
  padding-left: 187px;
  padding-right: 118px;
  margin-bottom: 10px;
}
.services-icons-and-content-cover {
  display: flex;
  align-items: center;
}
.services-icon-main-div {
  margin-right: 31px;
}
.why-choose-dizbuzz-title {
  font-size: 58px;
  color: #1d293f;
  line-height: 64px;
  font-family: sen-bold;
  margin-bottom: 121px;
  margin-top: 121px;
}
@media (max-width: 1355px) {
  .services-cover-div {
    padding-top: 89px;
    padding-bottom: 84px;
    background-color: #f9f9fb;
    padding-left: 40px;
    padding-right: 40px;
  }
  .white-services-cover-div {
    padding-top: 89px;
    padding-bottom: 84px;
    background-color: #f9f9fb;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 991px) {
  .service-card-margin-bottom {
    margin-bottom: 48px !important;
  }
}

@media (max-width: 649px) {
  .why-choose-dizbuzz-title {
    font-size: 30px;
    color: #1d293f;
    line-height: 44px;
    font-family: sen-bold;
    margin-bottom: 121px;
  }
  .white-services-cover-div {
    padding-top: 89px;
    padding-bottom: 84px;
    background-color: white;
    padding-left: 24px;
    padding-right: 24px;
  }
  .services-cover-div {
    padding-top: 89px;
    padding-bottom: 84px;
    background-color: #f9f9fb;
    padding-left: 24px;
    padding-right: 24px;
  }
  .service-card-margin-bottom {
    margin-bottom: 48px !important;
  }
}
