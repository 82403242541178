  #container {
    height: 1000;
    width: 200%;
    margin: 25px auto;
  }
  
  #box {
    -webkit-animation-name: move;
    -webkit-animation-duration: 14s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: right;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: move;
    -o-animation-name: move;
    animation-name: move;
    width: fit-content;
  }
  
  #box:hover {
    -webkit-animation-play-state: paused;
  }

  #box-comment {
    -webkit-animation-name: move;
    -webkit-animation-duration: 24s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: right;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: move;
    -o-animation-name: move;
    animation-name: move;
    width: fit-content;
  }
  
  #box-comment:hover {
    -webkit-animation-play-state: paused;
  }
  
  @-moz-keyframes move {
    0% {
        margin-left: -400px;
      }
      100% {
        margin-left: 2px;
      }
  }
    @-o-keyframes move  {
        0% {
            margin-left: -400px;
          }
          100% {
            margin-left: 2px;
          }
        }
  @keyframes move {
    0% {
      margin-left: -400px;
    }
    100% {
      margin-left: 2px;
    }
  }
  @-webkit-keyframes move {
    0% {
      margin-left: -400px;
    }
    100% {
      margin-left: 2px;
    }
  }

  @media (max-width: 649px) {
    #box-comment{
      animation-name: moving;
    }
    #box{
      animation-name: moves;
    }
    #container {
      width: 500%;
    }
    @keyframes moves {
      0%{
          transform: translateX(100vw);
      }
      100%{
          transform: translateX(-170vw);
      }
  }
  @keyframes moving {
      0%{
          transform: translateX(100vw);
      }
      100%{
          transform: translateX(-200vw);
      }
  }
  }