.testimony_scroll {
    animation: moving 30s  infinite ;
    -webkit-animation: moving 30s linear infinite;
    animation: moving 30s linear infinite  ;
}
.people_scroll{
    -webkit-animation: move 17s linear infinite;
    animation: move 17s linear infinite  ;
    overflow: scroll;
}

.scrolling{
    display: flex;
}

.scrolling:hover > .testimony_scroll{
    -webkit-animation-play-state: paused;
}
.scrolling:hover > .people_scroll{
    -webkit-animation-play-state: paused;
}

@keyframes move {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-100%);
    }
}

@-webkit-keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
@keyframes moving {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-100%);
    }
}

@-webkit-keyframes moving {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
/* @keyframes moving {
    0%{
        transform: translateX(100vw);
    }
    100%{
        transform: translateX(-100vw);
    }
} */
/* @media (max-width: 1024px){
    .testimony_scroll {
        animation: moving 5s linear infinite ;
    }
    .people_scroll{
        animation: move 14s linear infinite  ;
        overflow: scroll;
    }
    
    .testimony_scroll:hover  {
        -webkit-animation-play-state: paused;
    }
    
    .people_scroll:hover  {
        -webkit-animation-play-state: paused;
    }
    
    @keyframes move {
        0%{
            transform: translateX(100vw);
        }
        100%{
            transform: translateX(-250vw);
        }
    }
    @keyframes moving {
        0%{
            transform: translateX(100vw);
        }
        100%{
            transform: translateX(-500vw);
        }
    }
} */
/* @media (max-width: 649px) {
    .testimony_scroll {
        animation: moving 30s  infinite ;
    }
    .people_scroll{
        animation: move 17s  infinite  ;
        overflow: scroll;
    }
    
    .testimony_scroll:hover  {
        -webkit-animation-play-state: paused;
    }
    
    .people_scroll:hover  {
        -webkit-animation-play-state: paused;
    }
    
    @keyframes move {
        0%{
            transform: translateX(100vw);
        }
        100%{
            transform: translateX(-430vw);
        }
    }
    @keyframes moving {
        0%{
            transform: translateX(100vw);
        }
        100%{
            transform: translateX(-500vw);
        }
    }
    
  } */
