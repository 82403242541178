.sidebar-cover{
    display: none;
}
@media (max-width:869px) {

    .sidebar-cover{
        display: block;
        position: absolute;
        height:calc(100vh - 104px);
        background-color: white;
        width: 100%;
        z-index: 1000;
        padding-left: 38px;
        padding-top: 20px;
        &-ul{
            list-style: none;
            padding: 0px;
            &-li{
                font-size: 16px;
                font-family: sen-bold;
                color: #033C59;
                margin-bottom: 20px;
                cursor: pointer;
            }
        }

    }
}