.landing-page-cover {
  overflow-x: hidden !important;
}
.tab-and-mobile-carousel-form {
  display: none;
}

@media (min-width: 1200px) {
  .tab-and-mobile-carousel-form {
    display: block;
  }
}

// @media (max-width: 1221px) {
//   .tab-and-mobile-carousel-form {
//     // display: block;
//   }
// }
