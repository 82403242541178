.about-us-div-cover{
    overflow-x: hidden;

}
.about-content-cover-div{
    padding-left: 210px;
    padding-right: 50px;
    padding-top:70px
}
.about-title-content-div{
    font-size: 58px;
    line-height: 64px;
    letter-spacing: -1px;
    color: #1D293F;
font-family: sen-bold;
padding-top: 48px;
margin-bottom: 45px;
}
.about-us-content-div{
    color: #1D293F;
    font-size: 18px;
    line-height: 32px;
    font-family: sen-light;
}
.about-us-image-and-content-div{
    padding-bottom: 151px;
    border-bottom:1px solid #7C8087;

}
@media (max-width:1355px) {
    .about-content-cover-div{
        padding-left: 40px;
        padding-right: 50px;
        padding-top:70px
    }
}
@media (max-width:649px) {
    .about-us-div-cover{
        overflow-x: hidden;

    }
    .about-content-cover-div{
        padding-left:24px;
        padding-right: 50px;
        padding-top:70px
    }
    .about-title-content-div{
        font-size: 30px;
        line-height: 44px;
        letter-spacing: -1px;
        color: #1D293F;
    font-family: sen-bold;
    padding-top: 48px;
    margin-bottom: 45px;
    }
    .about-us-content-div{
        color: #1D293F;
        font-size: 18px;
        line-height: 32px;
        font-family: sen-light;
    }
    .about-us-image-and-content-div{
        padding-bottom: 151px;
        border-bottom:1px solid #7C8087;

    }
}
