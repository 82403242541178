.carousel-form-main-cover {
  //   background-color: blue;
}
.carousel-form-main-button-cover {
  display: flex;
  justify-content: flex-end;
}
.carousel-form-cover-background {
  background-color: white;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 41px;
  padding-bottom: 39px;
  border-radius: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.illusion-input {
  display: flex;
  width: 100%;
  height: 51px;
  align-items: center;
  border-color: #47b638;
  border: 2px solid #47b638;
  border-radius: 5px;
  position: relative;
  transition: none;
}

.form-wrapper {
  flex-shrink: 1 !important;
  width: 80% !important;
  max-width: 100% !important;
  padding-right: unset !important;
  padding-left: unset !important;
  margin-top: unset !important;
}

.error {
  font-size: 10px;
  font-family: sen-medium;
  width: 100%;
  color: red;
  margin-bottom: 10px;
  text-align: center;
}

.currency-container {
  position: absolute;
  display: flex;
  padding-left: 12px;
  // width: 12%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 1220px){
  .carousel-form-cover-background{
    height: fit-content;
  }

  .form-wrapper{
    display: flex;
    flex: 1;
  }

  .margin-bottom-for-tab-and-mobile {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .carousel-form-main-button-cover {
    display: flex;
    justify-content: flex-start;
    
  }
  .margin-bottom-for-tab-and-mobile {
    margin-bottom: 14px;
    margin-right: 5px;
    
  }
}
@media (max-width: 649px) {
  .carousel-form-cover-background {
    background-color: white;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 41px;
    padding-bottom: 39px;
    border-radius: 24px;
    
    
  }
  .has-mobile-button-width {
    width: 100% !important;
  }
}
