.banner-cover{
    padding-top: 219px;
    padding-bottom: 141px;
    background-image: url("../../images/banner-image.svg");
    background-repeat: no-repeat;
    background-position: center;

}
.banner-title{
    width: 100%;
    text-align: center;
    font-size: 58px;
    line-height: 64px;
    color: white;
    font-family: sen-bold;
    display: flex;
    justify-content: center;
    margin-bottom: 33px;
}
.banner-subtitle-div{
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: white;
    font-family: sen-bold;
    margin-bottom: 44px;

}
.banner-button-div{
display: flex;
justify-content: center;
}
.banner-br{
    display: block ;
}
@media (max-width:649px) {
    .banner-cover{
        padding-top: 219px;
        padding-bottom: 141px;
        background-image: url("../../images/banner-image.svg");
        background-repeat: no-repeat;
        background-position: center;
        padding-left: 24px;
        padding-right: 24px;

    }
.banner-cover-button{
    width: 100% !important;
}
.banner-br{
    display: none ;
}
.banner-title{
    width: 100%;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    color: white;
    font-family: sen-bold;
    display: flex;
    justify-content: center;
    margin-bottom: 33px;
}
}
