.we-are-here-to-help {
  padding-top: 164px;
  padding-bottom: 228px;
  padding-left: 145px;
  padding-right: 160px;
  background-color: rgba(71, 182, 56, 0.05);
  margin-top: -100px;
}
.we-are-here-title-cover {
  display: flex;
  justify-content: center;
}
.we-are-here-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 56px;
  padding-bottom: 36px;
  border-radius: 10px;
}

.loadCalculatorMobile{
  display: none;
}

@media (max-width: 1355px) {
  .we-are-here-to-help {
    padding-top: 164px;
    padding-bottom: 228px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 991px) {
  .we-are-here-to-help-main-car-cover {
    margin-bottom: 48px;
  }
}
@media (max-width: 649px) {
  .we-are-here-to-help {
    padding-top: 164px;
    padding-bottom: 228px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .we-are-here-to-help-title {
    font-size: 30px !important;
    line-height: 44px !important;
  }
  .we-are-here-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .loadCalculatorMobile{
    display: flex;
    margin-bottom: 30px;
  }
}
