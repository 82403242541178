.vision-and-mission-container {
  display: grid;
  grid-template-areas:
    "mission avatarA"
    "avatarB vision";
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 210px;
  padding-right: 50px;
  padding-top: 70px;
  column-gap: 54px;
}

.mission-container {
  grid-area: mission;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 32px;
}

.mission-title {
  width: 100%;
  font-size: 58px;
  line-height: 64px;
  letter-spacing: -1px;
  color: #1d293f;
  font-family: sen-bold;
  padding-top: 48px;
  margin-bottom: 45px;
}

.mission-content {
  width: 100%;
  color: #1d293f;
  font-size: 18px;
  line-height: 32px;
  font-family: sen-light;
}

.avatar-a-container {
  grid-area: avatarA;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: start;
}

.avatar-a-wrapper {
  background: url("../../images/avatar.png");
  background-size: cover;
  background-position: top center;
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.avatar-b-container {
  grid-area: avatarB;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: start;
}

.avatar-b-wrapper {
  background: url("../../images/vector2.png");
  background-size: cover;
  background-position: top center;
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.vision-container {
  grid-area: vision;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.vision-title {
  width: 100%;
  font-size: 58px;
  line-height: 64px;
  letter-spacing: -1px;
  color: #1d293f;
  font-family: sen-bold;
  padding-top: 48px;
  margin-bottom: 45px;
}

.vision-content {
  width: 100%;
  color: #1d293f;
  font-size: 18px;
  line-height: 32px;
  font-family: sen-light;
}

@media (max-width: 1355px) {
  .vision-and-mission-container {
    padding-left: 40px;
    padding-right: 50px;
    padding-top: 70px;
  }
}

@media (max-width: 900px) {
  .vision-and-mission-container {
    column-gap: 12px;
  }
  .avatar-a-container {
    justify-self: center;
  }
  .avatar-b-container {
    justify-self: center;
  }
  .avatar-a-wrapper {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
  .avatar-b-wrapper {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
}

@media (max-width: 649px) {
  .vision-and-mission-container {
    padding-left: 24px;
    padding-right: 50px;
    padding-top: 70px;
    grid-template-areas:
      "avatarA"
      "mission"
      "avatarB"
      "vision";
    grid-template-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 0px;
  }
  .mission-container {
    grid-column: span 12;
  }
  .vision-container {
    grid-column: span 12;
  }
  .avatar-a-container {
    grid-column: span 12;
  }
  .avatar-b-container {
    grid-column: span 12;
  }
  .avatar-a-wrapper {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
  .avatar-b-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .mission-title {
    margin-bottom: 0px;
  }
  .vision-title {
    margin-bottom: 0px;
  }
}
