.learn-more-cover {
  padding-left: 205px;
  padding-right: 205px;
  background-color: rgba(71, 182, 56, 0.16);
  padding-top: 120px;
  padding-bottom: 120px;
}

.learn-more-title {
  color: #1d293f;
  font-family: sen-bold;
  line-height: 64px;
  letter-spacing: -1px;
  font-size: 58px;
  width: 60%;
  margin-bottom: 50px;
  margin-top: 80px;
}
.learn-more-content-div {
  font-size: 18px;
  line-height: 32px;
  color: #1d293f;
  font-family: sen-medium;
}

@media (max-width: 1355px) {
  .learn-more-cover {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .learn-more-mobile-button {
    width: 100% !important;
  }
  .learn-more-title {
    color: #1d293f;
    font-family: sen-bold;
    line-height: 44px;
    letter-spacing: -1px;
    font-size: 30px;
    width: 100%;
    margin-bottom: 65px;
    margin-top: 120px;
  }
  .learn-more-cover {
    padding-left: 24px;
    padding-right: 24px;
  }
  .age-div {
    margin-bottom: 44px;
  }
}
