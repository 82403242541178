.client-comment-cover{
    border: 1px solid #E2E4E8;
    border-radius: 10px;
    padding-top: 30px;
    padding-left: 27px;
    padding-right: 33px;
    padding-bottom: 28px;
    width: 385px;
    margin-right: 30px;
}
.comment-image-div{
    margin-bottom: 25px;
}
.comment-content-div{
    font-size: 16px;
    color: #1D293F;
    line-height: 28px;
    font-family: sen-bold;
    margin-bottom: 12px;
}
.comment-name-div{
    font-size: 18px;
    line-height: 32px;
    color: #1D293F;
    font-family: sen-bold;
}

