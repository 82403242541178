.loan-type-card {
  width: 408px !important;
  height: 565px;
  position: relative;
  margin-right: 24px;
}
.loan-type-caption-div {
  position: absolute;
  top: 70%;
  padding-left: 39px;
  padding-right: 38px;
}

