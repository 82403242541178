.testimony-cover-page {
  padding-top: 24px;
  padding-left: 240px;
  padding-bottom: 2px;
}
.testimony-title-div {
  font-size: 58px;
  line-height: 64px;
  color: #1d293f;
  font-family: sen-bold;
  letter-spacing: -1px;
  margin-bottom: 80px;
}
.testimony-root-cover-div {
  position: relative;
  display: flex;
  padding-bottom: 30px;
}
.testimony-card-list-div {
  display: flex;
}
.love-image-div {
  margin-bottom: 34px;
}
.testimony-left-icon {
  margin-right: 41px;
  cursor: pointer;
}
.testimony-right-icon {
  cursor: pointer;
}

.controller-div {
}

@media (max-width: 1355px) {
  .testimony-cover-page {
    padding-top: 246px;
    padding-left: 40px;
    padding-bottom: 134px;
  }
  .testimony-root-cover-div {
    position: relative;
    display: flex;

    padding-bottom: 30px;
    overflow-x: scroll;
  }
}

@media (max-width: 649px) {
  .testimony-cover-page {
    padding-top: 246px;
    padding-left: 24px;
    padding-bottom: 134px;
  }
  .testimony-root-cover-div {
    position: relative;
    display: flex;

    padding-bottom: 30px;
    overflow-x: scroll;
  }
  .testimony-title-div {
    font-size: 30px;
    line-height: 44px;
    color: #1d293f;
    font-family: sen-bold;
    letter-spacing: -1px;
    margin-bottom: 77px;
  }

  .controller-div {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
    padding-right: 24px !important;
  }
}
