.contact-us-form-cover {
  padding-top: 80px;
  padding-left: 113px;
  padding-right: 180px;
  overflow-x: hidden !important;
}

.contact-us-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
}
.contact-us-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.contact-us-text {
  font-weight: 700;
  color: #091a24;
  font-size: 48px;
  text-align: center;
}
.contact-us-description {
  font-weight: 400;
  color: #091a24;
  font-size: 20px;
  text-align: center;
}
.contact-us-cards-wrapper {
  display: flex;
}
.contact-us-card-one {
  flex: 1 0 auto;
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-us-card-two {
  flex: 1 0 auto;
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-us-card-three {
  flex: 1 0 auto;
  display: flex;
  width: 33.3%;
  flex-direction: column;
  align-items: center;
}

.form-title {
  font-size: 58px;
  color: #1d293f;
  line-height: 64px;
  letter-spacing: -1px;
  margin-bottom: 41px;
  font-family: sen-bold;
}
.contact-number-div {
  display: flex;
  margin-bottom: 14px;
}
.contact-email-div {
  margin-bottom: 84px;
}
.contact-us-button-div {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.contact-us-form-container {
  display: flex;
  background-color: #00000052;
  color: #ffffff;
  margin-top: 48px;
}
.contact-us-form-wrapper {
  padding: 64px 128px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.personal-field-input {
  display: flex;
  column-gap: 2px;
}
.name-input-wrapper {
  flex: 0 0 auto;
  width: 50%;
}
.email-input-wrapper {
  flex: 0 0 auto;
  width: 50%;
}
@media (max-width: 1355px) {
  .contact-us-form-wrapper {
    padding: 12px 24px;
  }
  .personal-field-input {
    flex-direction: column;
  }
  .name-input-wrapper {
    flex: 0 0 auto;
    width: 100%;
  }
  .email-input-wrapper {
    flex: 0 0 auto;
    width: 100%;
  }
  .contact-us-form-cover {
    padding-top: 80px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 900px) {
  .contact-us-cards-wrapper {
    flex-direction: column;
  }
  .contact-us-card-one {
    width: 100%;
  }
  .contact-us-card-two {
    width: 100%;
  }
  .contact-us-card-three {
    width: 100%;
  }
}
