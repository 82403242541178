.loan-calculator-cover {
  padding-top: 246px;
  padding-bottom: 168px;
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
  min-height: 100vh;
  background: linear-gradient(
      to bottom,
      rgb(0, 0, 0, 0.52),
      rgb(67, 67, 67, 0.73)
    ),
    url("../../images/loan-calculator.jpg") no-repeat;
  background-color: green;
  // background-size: contain;

  // padding-right: 62px;
  background-repeat: no-repeat;
  padding-right: 62px;
}
.loan-calculator-form-cover {
  background: white;
  padding-left: 50px;
  padding-right: 46px;
  padding-bottom: 64px;
  padding-top: 51px;
}
.loan-calculator-wrapper {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.loan-calculator-title {
  font-size: 36px;
  line-height: 28px;
  font-family: sen-bold;
  color: #1d293f;
  margin-bottom: 51px;
}
.loan-input-details-div {
  display: flex;
  width: 100%;
  padding-right: 5px !important;
}
.loan-input-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1 0 auto;
}
.loan-term-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1 0 auto;
}
.form-calculator {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
@media (max-width: 649px) {
  .loan-input-details-div {
    justify-content: flex-end;
  }
  .loan-calculator-cover {
    padding-top: 246px;
    padding-bottom: 168px;
    display: flex;
    justify-content: flex-end;
    background-image: url("../../images/loan-calculator.jpg");
    // background-color: green;
    // background-size: contain;
    padding-right: 0px;
    background-repeat: no-repeat;
  }
  .loan-calculator-form-cover {
    background: transparent;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 64px;
    padding-top: 51px;
  }
  .loan-calculator-title {
    font-size: 36px;
    line-height: 28px;
    font-family: sen-bold;
    color: white;
    margin-bottom: 51px;
  }
  .loan-input-details-div {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px !important;
  }
  .loan-input-wrapper {
    flex-direction: row;
    row-gap: 0px;
    width: 100%;
  }
  .loan-term-wrapper {
    flex-direction: row;
    row-gap: 0px;
    width: 100%;
  }
}
