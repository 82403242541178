
.loan-type-comp-cover {
  padding-left: 62px;
  padding-right: 62px;
}
.loan-type-card-root-cover-div {
  position: relative;
  display: flex;
  padding-bottom: 30px;
}

.loan-root-cover-div {
  position: relative;
  display: flex;
  padding-bottom: 30px;
}
.loan-card-list-div {
  display: flex;
}

.loan-left-icon {
  margin-right: 41px;
  cursor: pointer;
}
.loan-right-icon {
  cursor: pointer;
}
.button-wrapper {
  padding: 24px 0px;
}
.controller-div {
}
@media (max-width: 649px) {
  .loan-type-comp-cover {
    padding-left: 0px;
    padding-right: 0px;
  }
  .controller-div {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
    padding-right: 24px !important;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body { font-family: sans-serif; }

.gallery {
  background: #EEE;
}

.gallery-cell {
  width: 66%;
  height: 200px;
  margin-right: 10px;
  background: #8C8;
  counter-increment: gallery-cell;
}

/* cell number */
.gallery-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
